<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Rank each of the compounds shown below from most polar down to least polar.
      </p>

      <p class="pl-8 mb-4">
        <v-img style="max-width: 279px" :src="imageName" />
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="choices"
        class="mb-0"
        style="max-width: 430px"
        prepend-text="Most Polar"
        append-text="Least Polar"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LCPL4AQ10',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      items: ['Molecule A', 'Molecule B', 'Molecule C', 'Molecule D'],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/uciPL4AQ10.png';
    },
    seed() {
      return this.$inertia.getSeed();
    },
    choices() {
      return seededShuffle(this.items, this.seed);
    },
  },
};
</script>
